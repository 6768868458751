<template>
  <div class="userMain">
    <el-row :gutter="10">
      <el-col :xs="0" :lg="6" :offset="0">
        <el-card>
          <div style="text-align: center">
            <img
              :src="avatar"
              alt=""
              style="border-radius: 100px; height: 150px; width: 150px"
            />
            <p>{{ username }}</p>
            <p v-loading="loading">当前余额:{{ money }} CNY</p>
            <button @click="$router.push('/pay')">充值</button>
            <button
              style="background-color: #cb1414; color: white; margin-top: 10px"
              @click="logOut()"
            >
              退出登录
            </button>
          </div>
          <div style="text-align: center">
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 1 }"
              @click="chooseMenu(1)"
            >
              基本资料
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 2 }"
              @click="chooseMenu(2)"
            >
              头像设置
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 3 }"
              @click="chooseMenu(3)"
            >
              密码修改
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 8 }"
              @click="chooseMenu(8)"
            >
              我的优惠卷
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 4 }"
              @click="chooseMenu(4)"
            >
              我的订单
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 5 }"
              @click="chooseMenu(5), getProductss()"
            >
              我的产品
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 6 }"
              @click="chooseMenu(6), getServices()"
            >
              我的服务
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 7 }"
              @click="chooseMenu(7)"
            >
              云服务器
            </p>
            <!-- <p
              class="menu"
              :class="{ activeMenu: showMenu === 9 }"
              @click="chooseMenu(9)"
            >
              我的需求
            </p> -->
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :lg="18" :offset="0">
        <el-card>
          <div v-if="showMenu === 1">
            <!-- <el-popover
              placement="top-start"
              trigger="hover"
              content="实时汇率来自OKX欧易交易所"
            >
              <p slot="reference" v-loading="loading">
                账户余额：{{ money }} CNY
                <i
                  class="fa fa-info"
                  style="
                    border: 1px solid black;
                    border-radius: 100px;
                    padding: 3px 8px;
                  "
                ></i>
              </p>
            </el-popover> -->
            <p v-loading="loading">账户余额：{{ money }} CNY</p>
            <el-row>
              <el-col :xs="12" :lg="8">
                <p>当前邮箱：{{ username }}</p>
              </el-col>
              <el-col :xs="12" :lg="4">
                <button @click="changeMailDialog = true">修改邮箱</button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="12" :lg="8">
                <p>手机号码：{{ mobile }}</p>
              </el-col>
              <el-col :xs="12" :lg="4">
                <button @click="phoneBindDialog = true">绑定手机</button>
              </el-col>
            </el-row>
          </div>
          <div v-if="showMenu === 2">
            <el-row :gutter="20">
              <el-col :xs="24" :lg="12" :offset="0">
                <h1>当前头像：</h1>
                <img
                  :src="avatar"
                  alt=""
                  style="border-radius: 100px; height: 150px; width: 150px"
                />
              </el-col>
              <el-col :xs="24" :lg="12" :offset="0">
                <h1>上传新头像：</h1>
                <el-upload
                  class="avatar-uploader"
                  :before-upload="beforeAvatarUpload"
                  :show-file-list="false"
                  accept=".jpg,.png"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-progress
                  type="circle"
                  v-show="upload"
                  :percentage="progress"
                  style="
                    position: absolute;
                    margin-top: -160px;
                    margin-left: 25px;
                    background-color: white;
                  "
                ></el-progress>
                <button
                  v-if="imageUrl"
                  style="margin-top: 20px"
                  @click="setNewAvatars()"
                >
                  确认设置头像
                </button>
              </el-col>
            </el-row>
          </div>
          <div v-if="showMenu === 3">
            <div style="display: flex">
              <i
                class="el-icon-message"
                style="
                  background-color: #659c41;
                  color: white;
                  border-radius: 50px;
                  font-size: 25px;
                  padding: 10px;
                "
              ></i>
              <p>您当前邮箱账号为：{{ username }}</p>
            </div>
            <el-form>
              <!-- <el-form-item label="请输入邮箱:" style="width: 100%">
                <el-input v-model="reset.email">
                  <template slot="suffix">
                    <el-button
                      v-if="countdown === 0"
                      type="text"
                      style="color: #cb1414"
                      @click="getMailCaptchas()"
                      >发送验证码</el-button
                    >
                    <el-button
                      v-if="countdown != 0"
                      type="text"
                      style="color: #cb1414; cursor: not-allowed"
                      >{{ countdown }}后可再次发送</el-button
                    >
                  </template>
                </el-input>
              </el-form-item> -->
              <el-form-item label="请输入验证码:" style="width: 100%">
                <!-- <el-input v-model="reset.pwd"></el-input> -->
                <el-input v-model="reset.captcha">
                  <template slot="suffix">
                    <el-button
                      v-if="countdown === 0"
                      type="text"
                      style="color: #cb1414"
                      @click="getMailCaptchas()"
                      >发送验证码</el-button
                    >
                    <el-button
                      v-if="countdown != 0"
                      type="text"
                      style="color: #cb1414; cursor: not-allowed"
                      >{{ countdown }}后可再次发送</el-button
                    >
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="请输入新密码:" style="width: 100%">
                <el-input v-model="reset.pwd"></el-input>
              </el-form-item>
            </el-form>
            <button type="primary" v-if="reset.pwd" @click="resetpwds()">
              确认修改密码
            </button>
          </div>
          <div v-if="showMenu === 4">
            <MyOrder />
          </div>
          <div v-if="showMenu === 5">
            <MyProduct @custom-event="gameReceiveFromChild" />
          </div>
          <div v-if="showMenu === 6">
            <el-row :gutter="20">
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card>
                  <div style="display: flex">
                    <img
                      src="https://api.jisuqipai.online/uploads/gamechange.png"
                      style="width: 50px; height: 50px"
                      alt=""
                    />
                    <p>游戏换皮服务</p>
                  </div>
                  <h1 style="text-align: center" v-loading="servicesLoading">
                    {{ service.gameChange }}
                  </h1>
                </el-card>
              </el-col>
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card>
                  <div style="display: flex">
                    <img
                      src="https://api.jisuqipai.online/uploads/sendlydev.png"
                      style="width: 50px; height: 50px"
                      alt=""
                    />
                    <p>二次开发服务</p>
                  </div>
                  <h1 style="text-align: center" v-loading="servicesLoading">
                    {{ service.sendDev }}
                  </h1>
                </el-card>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 10px">
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card
                  style="cursor: pointer"
                  @click.native="chooseMenu(100)"
                >
                  <div style="display: flex">
                    <img
                      src="https://api.jisuqipai.online/uploads/fixed.png"
                      style="width: 50px; height: 50px"
                      alt=""
                    />
                    <p>外包维护包月服务</p>
                  </div>
                  <h1 style="text-align: center" v-loading="servicesLoading">
                    {{ service.fixed }}
                  </h1>
                </el-card>
              </el-col>
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card>
                  <div style="display: flex">
                    <img
                      src="https://api.jisuqipai.online/uploads/custom.png"
                      style="width: 50px; height: 50px"
                      alt=""
                    />
                    <p>个性化定制服务</p>
                  </div>
                  <h1 style="text-align: center" v-loading="servicesLoading">
                    {{ service.custom }}
                  </h1>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div v-if="showMenu === 7">
            <el-row :gutter="20">
              <el-col
                :xs="24"
                :lg="12"
                v-loading="serverLoading"
                v-for="item in serverList"
                :key="item.id"
                style="margin-bottom: 10px"
              >
                <el-card>
                  <div style="display: flex">
                    <img
                      :src="item.image"
                      alt="flag"
                      style="width: 80px; height: 50px; border-radius: 5px"
                    />
                    <span style="margin-left: 10px">{{ item.title }}</span>
                  </div>
                  <h1 style="margin: 0; text-align: center">
                    {{ item.count }}
                  </h1>
                  <div style="float: right; margin: 10px 0">
                    <button @click="$router.push('/server/' + item.id)">
                      +创建
                    </button>
                    <button
                      v-if="item.count > 0"
                      @click="openServerList(item.area)"
                    >
                      管理
                    </button>
                    <button
                      v-else
                      disabled
                      style="cursor: no-drop; background-color: #ccc"
                    >
                      管理
                    </button>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div v-if="showMenu === 8">
            <!-- {{ activity }} -->
            <el-row gutter="10">
              <el-col
                :xs="12"
                :lg="8"
                v-for="item in activity"
                :key="item.id"
                style="margin: 10px 0"
              >
                <div
                  :class="item.count === 0 ? 'redBag_dark' : 'redBag'"
                  v-loading="acloading"
                >
                  <div>
                    <span
                      style="margin: 0; font-size: 20px; font-weight: bolder"
                      >￥{{ item.min_amount }}</span
                    >
                    <span
                      style="
                        float: right;
                        border-radius: 30px;
                        color: white;
                        padding: 0 8px;
                      "
                      :style="{
                        backgroundColor:
                          item.count === 0 ? '#cccccc' : '#ff6666',
                      }"
                    >
                      {{ item.count }}张</span
                    >
                  </div>
                  <div style="font-size: 12px; margin-top: 10px">
                    满{{ item.pack_amount }}元使用
                  </div>
                  <div style="font-size: 12px; margin-top: 10px; display: flex">
                    <span style="flex: 1" v-if="item.count != 0"
                      >到期时间：{{ formatDate(item.expired_at) }}</span
                    >
                    <span style="flex: 1" v-else>暂无此优惠卷</span>
                    <button
                      @click="$router.push('/')"
                      v-if="item.count != 0"
                      style="font-size: 12px; padding: 5px"
                    >
                      去使用
                    </button>
                    <button
                      v-else
                      style="
                        font-size: 12px;
                        padding: 5px;
                        background-color: #cccccc;
                        cursor: not-allowed;
                      "
                    >
                      去使用
                    </button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <!-- 外包维护包月服务详情 -->
          <div v-if="showMenu === 100">
            <el-breadcrumb>
              <el-breadcrumb-item>
                <span>我的位置：</span>
                <span
                  @click="chooseMenu(1)"
                  style="cursor: pointer"
                  class="clickable"
                  >个人中心</span
                >
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <span
                  @click="chooseMenu(6)"
                  style="cursor: pointer"
                  class="clickable"
                  >我的服务</span
                >
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <span
                  @click="chooseMenu(100)"
                  style="cursor: pointer"
                  class="clickable"
                  >外包维护包月服务</span
                >
              </el-breadcrumb-item>
            </el-breadcrumb>
            <el-table
              :data="serviceList"
              v-loading="serviceLoading"
              stripe
              style="width: 100%"
            >
              <el-table-column prop="id" label="ID" width="50">
              </el-table-column>
              <el-table-column prop="product" label="对应产品" width="150">
                <template slot-scope="scope">
                  <div style="display: flex">
                    <img
                      v-if="scope.row.product === 'YonoGames'"
                      src="https://api.jisuqipai.online/uploads/yono.png"
                      alt=""
                      style="border-radius: 50px; width: 30px; height: 30px"
                    />
                    <img
                      v-else-if="scope.row.product === 'CokeJogo'"
                      src="https://api.jisuqipai.online/uploads/coke.png"
                      alt=""
                      style="border-radius: 50px; width: 30px; height: 30px"
                    />
                    <p style="margin: 5px">
                      {{ scope.row.product }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="product_code" label="项目编号">
              </el-table-column>
              <el-table-column prop="product_name" label="项目名称">
              </el-table-column>
              <el-table-column prop="status" label="项目状态">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.status === 0"
                    >待激活</el-tag
                  >
                  <el-tag type="success" v-else-if="scope.row.status === 1"
                    >正常</el-tag
                  >
                  <el-tag type="danger" v-else-if="scope.row.status === 2"
                    >到期</el-tag
                  >
                  <el-tag type="info" v-else-if="scope.row.status === 3"
                    >违约终止</el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column prop="amount_month" label="包月价格">
              </el-table-column>
              <el-table-column prop="renewal_cycle" label="续费周期">
                <!-- <template slot-scope="scope">
                  <el-tag v-if="scope.row.renewal_cycle === 0">半月付</el-tag>
                  <el-tag v-else-if="scope.row.renewal_cycle === 1"
                    >月付</el-tag
                  >
                </template> -->
              </el-table-column>
              <el-table-column prop="demand" label="需求开发">
              </el-table-column>
              <el-table-column prop="bug" label="Bug缺陷"> </el-table-column>
              <el-table-column prop="create_time" label="创建时间">
                <template slot-scope="scope">
                  {{ formatDate(scope.row.create_time) }}
                </template>
              </el-table-column>
              <el-table-column prop="end_time" label="到期时间">
                <template slot-scope="scope">
                  {{ formatDate(scope.row.end_time) }}
                </template>
              </el-table-column>
              <el-table-column label="剩余时间">
                <template slot-scope="scope">
                  <span
                    v-if="
                      formattedDay(scope.row.end_time - scope.row.create_time) >
                      daysInYear
                    "
                    >1年{{
                      formattedDay(scope.row.end_time - scope.row.create_time) -
                      daysInYear
                    }}天</span
                  >
                  <span v-else
                    >{{
                      formattedDay(scope.row.end_time - scope.row.create_time)
                    }}天</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.status === 0 || scope.row.status === 1"
                    type="primary"
                    @click="chooseProduct(scope.row.product_code)"
                    >管理</el-button
                  >
                  <el-button v-else type="primary" disabled>管理</el-button>
                  <el-button
                    v-if="
                      scope.row.status === 0 ||
                      scope.row.status === 1 ||
                      scope.row.status === 2
                    "
                    type="warning"
                    @click="
                      openRenewalDialog(
                        scope.row.product_code,
                        scope.row.amount_month,
                        scope.row.renewal_cycle
                      )
                    "
                    >续费</el-button
                  >
                  <el-button v-else disabled type="warning">续费</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 外包维护包月维护管理页面 -->
          <div v-if="showMenu === 101">
            <el-breadcrumb>
              <el-breadcrumb-item>
                <span>我的位置：</span>
                <span
                  @click="chooseMenu(1)"
                  style="cursor: pointer"
                  class="clickable"
                  >个人中心</span
                >
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <span
                  @click="chooseMenu(6)"
                  style="cursor: pointer"
                  class="clickable"
                  >我的服务</span
                >
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <span
                  @click="chooseMenu(100)"
                  style="cursor: pointer"
                  class="clickable"
                  >外包维护包月服务</span
                >
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <span
                  @click="chooseMenu(6)"
                  style="cursor: pointer"
                  class="clickable"
                  >项目编号：{{ product_code }}</span
                >
              </el-breadcrumb-item>
            </el-breadcrumb>
            <div style="margin-top: 20px; display: flex">
              <img
                src="https://api.jisuqipai.online/uploads/6740ab73a1bc7.png"
                alt=""
                style="border-radius: 50px; width: 50px; height: 50px"
              />
              <h1 style="margin: 6px">YonoGames</h1>
            </div>
            <el-row :gutter="20" style="margin-top: 20px">
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card
                  ><div style="display: flex">
                    <img
                      src="https://api.jisuqipai.online/uploads/dev.png"
                      alt=""
                      style="width: 50px; height: 50px; border-radius: 50px"
                    />
                    <h1 style="margin: 6px 10px">需求开发</h1>
                  </div>
                  <div style="display: flex">
                    <div style="text-align: center">
                      <p>总需求</p>
                      <h1 style="color: #cb1414">
                        {{ productInfo.needly.total }}
                      </h1>
                    </div>
                    <div style="text-align: center">
                      <p>待处理</p>
                      <h1 style="color: #cb1414">
                        {{ productInfo.needly.waiting }}
                      </h1>
                    </div>
                    <div style="text-align: center">
                      <p>已处理</p>
                      <h1 style="color: #cb1414">
                        {{ productInfo.needly.done }}
                      </h1>
                    </div>
                    <div style="text-align: center">
                      <p>已撤销</p>
                      <h1 style="color: #cb1414">
                        {{ productInfo.needly.cancel }}
                      </h1>
                    </div>
                    <div style="text-align: center">
                      <p>处理中</p>
                      <h1 style="color: #cb1414">
                        {{ productInfo.needly.doing }}
                      </h1>
                    </div>
                    <EchartVue
                      :options="chartOptions1"
                      width="100px"
                      height="100px"
                    />
                  </div>
                  <div style="text-align: right">
                    <button @click="needly()">管理</button>
                  </div>
                </el-card>
              </el-col>
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card
                  ><div style="display: flex">
                    <img
                      src="https://api.jisuqipai.online/uploads/bug.png"
                      alt=""
                      style="width: 50px; height: 50px; border-radius: 50px"
                    />
                    <h1 style="margin: 6px 10px">Bug缺陷</h1>
                  </div>
                  <div style="display: flex">
                    <div style="text-align: center">
                      <p>总需求</p>
                      <h1 style="color: #cb1414">
                        {{ productInfo.bugs.total }}
                      </h1>
                    </div>
                    <div style="text-align: center">
                      <p>待处理</p>
                      <h1 style="color: #cb1414">
                        {{ productInfo.bugs.waiting }}
                      </h1>
                    </div>
                    <div style="text-align: center">
                      <p>已处理</p>
                      <h1 style="color: #cb1414">
                        {{ productInfo.bugs.done }}
                      </h1>
                    </div>
                    <div style="text-align: center">
                      <p>已撤销</p>
                      <h1 style="color: #cb1414">
                        {{ productInfo.bugs.cancel }}
                      </h1>
                    </div>
                    <div style="text-align: center">
                      <p>处理中</p>
                      <h1 style="color: #cb1414">
                        {{ productInfo.bugs.doing }}
                      </h1>
                    </div>
                    <EchartVue
                      :options="chartOptions"
                      width="100px"
                      height="100px"
                    />
                  </div>
                  <div style="text-align: right">
                    <button @click="chooseMenu(103)">管理</button>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <!-- 需求开发管理页面 -->
          <div v-if="showMenu === 102">
            <DemandDev
              :productCode="product_code"
              @custom-event="receiveFromChild"
            />
          </div>
          <!-- Bug缺陷管理页面 -->
          <div v-if="showMenu === 103">
            <BugFix
              :productCode="product_code"
              @custom-event="receiveFromChild"
            />
          </div>
          <!-- 游戏管理 -->
          <div v-if="showMenu === 104">
            <GameManage
              :GameType="gameType"
              :GameOrder="gameOrder"
              @custom-event="receiveFromChild"
            />
          </div>
          <!-- 服务器管理 -->
          <div v-if="showMenu === 105">
            <ServerList
              :region_area="regionArea"
              @custom-event="receiveFromChild"
            ></ServerList>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="phone-menu">
      <i
        class="el-icon-s-unfold"
        @click="drawer = true"
        style="
          font-size: 30px;
          margin-top: 10px;
          background-color: white;
          padding: 15px 15px;
          text-align: center;
          border-radius: 100px;
        "
      ></i>
    </div>
    <!-- 手机抽屉 -->
    <el-drawer :visible.sync="drawer" direction="ltr">
      <div style="text-align: center">
        <img
          :src="avatar"
          alt=""
          style="border-radius: 100px; height: 50px; width: 50px"
        />
        <p>{{ username }}</p>
        <p v-loading="loading">当前余额:{{ amount }}</p>
        <button @click="$router.push('/pay')">USDT充值</button>
      </div>
      <div style="text-align: center">
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 1 }"
          @click="chooseMenu(1)"
        >
          基本资料
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 2 }"
          @click="chooseMenu(2)"
        >
          头像设置
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 3 }"
          @click="chooseMenu(3)"
        >
          密码修改
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 8 }"
          @click="chooseMenu(8)"
        >
          我的优惠卷
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 4 }"
          @click="chooseMenu(4)"
        >
          我的订单
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 5 }"
          @click="chooseMenu(5)"
        >
          我的产品
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 6 }"
          @click="chooseMenu(6)"
        >
          我的服务
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 7 }"
          @click="chooseMenu(7)"
        >
          云服务器
        </p>
        <!-- <p
          class="menu"
          :class="{ activeMenu: showMenu === 9 }"
          @click="chooseMenu(9)"
        >
          我的需求
        </p> -->
      </div>
    </el-drawer>
    <!-- 领取优惠卷 -->
    <el-dialog
      title="优惠卷领取提示"
      :visible.sync="getActivityDialog"
      width="30%"
    >
      <p>您还没有领取优惠券，是否想要现在领取红包优惠券？</p>
      <span slot="footer" class="dialog-footer">
        <button @click="getActivityDialog = false">否</button>
        <button type="primary" @click="$router.push('/activity')">是</button>
      </span>
    </el-dialog>
    <!-- 修改邮箱 -->
    <el-dialog title="邮箱修改" :visible.sync="changeMailDialog" width="30%">
      <el-form label-width="100px">
        <el-form-item label="当前账号邮箱">
          {{ username }}
        </el-form-item>
        <el-form-item label="请输入验证码">
          <el-input v-model="cap">
            <template slot="suffix">
              <el-button
                v-if="countdown === 0"
                type="text"
                style="color: #cb1414"
                @click="getMailCaptchas(1, 1)"
                >发送验证码</el-button
              >
              <el-button
                v-if="countdown != 0"
                type="text"
                style="color: #cb1414; cursor: not-allowed"
                >{{ countdown }}后可再次发送</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="请输入新邮箱">
          <el-input v-model="newMail"></el-input>
        </el-form-item>
        <el-form-item label="请输入新邮箱验证码">
          <el-input v-model="newCap">
            <template slot="suffix">
              <el-button
                v-if="newCountdown === 0"
                type="text"
                style="color: #cb1414"
                @click="getMailCaptchas(1, 2)"
                >发送验证码</el-button
              >
              <el-button
                v-if="newCountdown != 0"
                type="text"
                style="color: #cb1414; cursor: not-allowed"
                >{{ newCountdown }}后可再次发送</el-button
              >
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button @click="changeMailDialog = false">取 消</button>
        <button type="primary" @click="changeemails()">确 定</button>
      </span>
    </el-dialog>
    <!-- 绑定手机号 -->
    <el-dialog title="绑定手机号" :visible.sync="phoneBindDialog" width="30%">
      <el-form>
        <el-form-item label="请输入手机号">
          <el-input v-model="phone"></el-input>
        </el-form-item>
        <el-form-item label="请输入验证码">
          <el-input v-model="phonecap">
            <template slot="suffix">
              <el-button
                v-if="countdown === 0"
                type="text"
                style="color: #cb1414"
                @click="sends()"
                >发送验证码</el-button
              >
              <el-button
                v-if="countdown != 0"
                type="text"
                style="color: #cb1414; cursor: not-allowed"
                >{{ countdown }}后可再次发送</el-button
              >
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button @click="phoneBindDialog = false">取 消</button>
        <button type="primary" @click="changemobiles()">确 定</button>
      </span>
    </el-dialog>
    <!-- 续费弹窗 -->
    <el-dialog
      :title="'服务续费：' + renewalProductCode"
      v-loading="loading"
      :visible.sync="renewalDialog"
      width="30%"
    >
      <span>请选择服务续费时长</span>
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :lg="6"
          :offset="0"
          v-for="item in time"
          :key="item.id"
          style="margin: 10px 0"
        >
          <div
            @click="
              chooseServiceTime(item.time, item.discountValue, item.month)
            "
            :style="{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#ffeae6',
              borderRadius: '10px',
              border:
                serviceTime === item.time
                  ? '1px #ff0000 solid'
                  : '1px #666666 solid',
              boxShadow:
                serviceTime === item.time
                  ? '0 0 20px #ff0000'
                  : '0 0 0px #909399',
              transition: 'all 0.3s ease',
              lineHeight: '40px',
            }"
          >
            <i
              class="el-icon-caret-right"
              style="color: #1f62b5; font-size: 15px"
            ></i>
            <p style="font-size: 15px; margin: 8px">
              {{ item.time }}
            </p>

            <div
              v-if="item.discount != '无折扣'"
              style="
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 0;
                border-top: 50px solid red;
                border-left: 50px solid transparent;
                border-radius: 0 10px 0 0;
              "
            >
              <span
                style="
                  position: absolute;
                  top: -40px;
                  right: -30px;
                  left: -30px;
                  transform: rotate(45deg);
                  color: white;
                  font-size: 14px;
                "
              >
                {{ item.discount }}
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="float: right">
        <p v-if="discountValue > 0">
          应付价格：<span style="color: red; font-weight: bolder">{{
            renewaPrice * renewaMonth
          }}</span>
          优惠金额：<span style="color: red; font-weight: bolder">
            {{ renewaPrice * renewaMonth * discountValue }}
          </span>
          实际支付金额：<span style="color: red; font-weight: bolder">
            {{
              renewaPrice * renewaMonth -
              renewaPrice * renewaMonth * discountValue
            }}
          </span>
        </p>
        <p v-else>
          应付价格：<span style="color: red; font-weight: bolder">{{
            renewaPrice
          }}</span>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="renewalServices()">立即续费</button>
      </span>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  changeemail,
  changemobile,
  checkServerForUID,
  getActivityForUid,
  getAmount,
  getMailCaptcha,
  getProductInfo,
  getProducts,
  getService,
  // getServiceInfo,
  getServiceList,
  // getUSDTrate,
  renewalService,
  resetpwd,
  send,
  setNewAvatar,
  uploadImg,
} from "@/api/api";
import DemandDev from "./DemandDev.vue";
import GameManage from "./GameManage.vue";
import ServerList from "./ServerList.vue";
import router from "@/router/router";
import { Loading, Message } from "element-ui";
import moment from "moment";
import { nextTick, ref, watch } from "vue";
import EchartVue from "./EchartVue.vue";
import BugFix from "./BugFix.vue";
import MyOrder from "./MyOrder.vue";
import MyProduct from "./MyProduct.vue";
const avatar = localStorage.getItem("avatar");
const username = localStorage.getItem("username");
const amount = ref("");
const money = ref("");
const showMenu = ref(1);
const loading = ref(false);
const imageUrl = ref("");
const progress = ref(0);
const upload = ref(false);
const countdown = ref(0);
const newCountdown = ref(0);
const drawer = ref(false);
// const orderData = ref([]);
const reset = ref({
  email: "",
  pwd: "",
  captcha: "",
});
// const server = ref([]);
// const rate = ref(""); // 最新汇率
const activity = ref([]);
const acloading = ref(false);
const getActivityDialog = ref(false);
// const mail = ref("");
const cap = ref("");
const newMail = ref("");
const newCap = ref("");
const changeMailDialog = ref(false);
const phoneBindDialog = ref(false);
const phone = ref("");
const phonecap = ref("");
const mobile = ref("");
const serviceList = ref([]);
const serviceLoading = ref(false);
// const demandData = ref([]);
// const demandLoading = ref(false);
const product_code = ref("");
// const Urgency = ref("");
// const time = ref("");
// const page = ref(1);
// const pageSize = ref(10);
const service = ref([]);
const servicesLoading = ref(false);
const productList = ref([]);
const productLoading = ref(false);
const productInfo = ref([]);
const renewalDialog = ref(false);
const renewalProductCode = ref("");
const chartOptions = ref({
  title: {
    left: "center",
  },
  tooltip: {
    trigger: "item",
  },
  series: [
    {
      name: "Bug缺陷",
      type: "pie",
      radius: "50%",
      data: [],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
});
const time = ref([]);
const serviceTime = ref("");
const discountValue = ref(0);
const renewaMonth = ref(0);
const renewaPrice = ref(0);
const serverList = ref([]);
const regionArea = ref("");
const serverLoading = ref(false);
const gameOrder = ref("");
const gameType = ref("");
const daysInYear = moment().isLeapYear() ? 366 : 365;

function gameReceiveFromChild(menu, order, type) {
  changeGame(menu, order, type);
}

function changeGame(menu, order, type) {
  chooseMenu(menu);
  gameOrder.value = order;
  gameType.value = type;
}

function openServerList(area) {
  regionArea.value = area;
  chooseMenu(105);
}

function checkServerForUIDs() {
  serverLoading.value = true;
  serverLoading.value = true;
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  checkServerForUID(token, uid).then((res) => {
    if (res.data.code === 200) {
      serverList.value = res.data.data;
      serverLoading.value = false;
    } else {
      Message.error("获取服务器数据失败" + res.data.msg);
      serverLoading.value = false;
    }
  });
}

function renewalServices() {
  loading.value = true;
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const amount = renewaPrice.value * renewaMonth.value; // 应付金额
  // const discount = renewaPrice.value * discountValue.value; // 优惠金额
  const discount = renewaPrice.value * renewaMonth.value * discountValue.value; // 优惠金额
  renewalService(
    token,
    uid,
    renewalProductCode.value,
    renewaMonth.value,
    amount,
    discount
  ).then((res) => {
    if (res.data.code === 200) {
      Message.success("续费成功！");
      renewalDialog.value = false;
      getServiceLists();
      loading.value = false;
    } else {
      Message.error("续费失败！" + res.data.msg);
      loading.value = false;
    }
  });
}

function chooseServiceTime(time, discount, month) {
  serviceTime.value = time;
  discountValue.value = discount;
  renewaMonth.value = month;
  // Message.success(renewaPrice.value);
}

function extractNumber(str) {
  let match = str.match(/^\d+/); // 匹配以数字开头的部分
  return match ? match[0] : null; // 如果匹配到，返回数字；否则返回 null
}

function openRenewalDialog(productCode, amountMonth, renewalCycle) {
  renewalDialog.value = true;
  renewalProductCode.value = productCode;
  renewaPrice.value = amountMonth;
  renewaMonth.value = 0;
  // discountValue.valueOf = 0;
  // this.time.value = renewalCycle;
  var discount = "无折扣";
  var discountValue = 0;
  if (renewalCycle == "1个月") {
    discount = "无折扣";
    discountValue = 0;
  } else if (renewalCycle == "3个月") {
    discount = "9折";
    discountValue = 0.1;
  } else if (renewalCycle == "6个月") {
    discount = "8折";
    discountValue = 0.2;
  } else if (renewalCycle == "12个月") {
    discount = "7折";
    discountValue = 0.3;
  }
  time.value = [
    {
      id: 1,
      time: renewalCycle,
      discount: discount,
      discountValue: discountValue,
      month: parseInt(extractNumber(renewalCycle)),
    },
  ];
  console.log(time.value);
}

// 动态监听 productInfo 的变化并更新图表数据
watch(
  () => productInfo.value.bugs,
  (newBugs) => {
    chartOptions.value.series[0].data = [
      { value: newBugs.waiting, name: "待处理" },
      { value: newBugs.done, name: "已处理" },
      { value: newBugs.cancel, name: "已撤销" },
      { value: newBugs.doing, name: "处理中" },
    ];
  },
  { immediate: true, deep: true }
);

const chartOptions1 = ref({
  title: {
    left: "center",
  },
  tooltip: {
    trigger: "item",
  },
  series: [
    {
      name: "需求开发",
      type: "pie",
      radius: "50%",
      data: [],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
});

// 动态监听 productInfo 的变化并更新图表数据
watch(
  () => productInfo.value.needly,
  (newNeedly) => {
    chartOptions1.value.series[0].data = [
      { value: newNeedly.waiting, name: "待处理" },
      { value: newNeedly.done, name: "已处理" },
      { value: newNeedly.cancel, name: "已撤销" },
      { value: newNeedly.doing, name: "处理中" },
    ];
  },
  { immediate: true, deep: true }
);

// 计算剩余天数
function formattedDay(seconds) {
  console.log(seconds);
  if (!seconds) return "-";
  return Math.floor(seconds / (60 * 60 * 24)); // 转换成天
}

function getProductInfos() {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  getProductInfo(token, uid, product_code.value).then((res) => {
    if (res.data.code === 200) {
      productInfo.value = res.data.data;
    } else {
      Message.error("获取产品信息失败！" + res.data.msg);
    }
  });
}

function getProductss() {
  productLoading.value = true;
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  getProducts(token, uid).then((res) => {
    if (res.data.code === 200) {
      productList.value = res.data.data;
      productLoading.value = false;
    } else {
      Message.error("获取产品信息失败！" + res.data.msg);
      productLoading.value = false;
    }
  });
}

function getServices() {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  servicesLoading.value = true;
  getService(token, uid).then((res) => {
    if (res.data.code === 200) {
      service.value = res.data.data;
      servicesLoading.value = false;
    } else {
      Message.error("获取服务信息失败！" + res.data.msg);
      servicesLoading.value = false;
    }
  });
}

function receiveFromChild(data) {
  chooseMenu(data);
}

function needly() {
  // getServiceInfos();
  chooseMenu(102);
}

function chooseProduct(code) {
  chooseMenu(101);
  product_code.value = code;
  getProductInfos();
}

function getServiceLists() {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  serviceLoading.value = true;
  getServiceList(token, uid).then((res) => {
    if (res.data.code === 200) {
      serviceList.value = res.data.data;
      serviceLoading.value = false;
    } else {
      Message.error("获取需求列表失败！" + res.data.msg);
      serviceLoading.value = false;
    }
  });
}

// 发送手机验证码
function sends() {
  const event = "changemobile";
  send(phone.value, event).then((res) => {
    if (res.data.code === 1) {
      Message.success("发送成功！");
    } else {
      Message.error("发送失败！" + res.data.msg);
    }
  });
}

// 绑定手机
function changemobiles() {
  const token = localStorage.getItem("token");
  changemobile(token, phone.value, phonecap.value).then((res) => {
    if (res.data.code === 1) {
      Message.success("绑定成功！正在退出登录！");
      this.logOut();
    } else {
      Message.error(res.data.msg);
    }
  });
}

// 邮件重置
function changeemails() {
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("username");
  changeemail(token, email, cap.value, newMail.value, newCap.value).then(
    (res) => {
      if (res.data.code === 1) {
        Message.success("重置成功！正在退出登录！");
        this.logOut();
      } else {
        Message.error(res.data.msg);
      }
    }
  );
}
// 时间格式化
function formatDate(date) {
  return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
}

// 根据UID获取所有优惠卷
function getActivityForUids() {
  acloading.value = true;
  const uid = localStorage.getItem("uid");
  if (!uid) {
    Message.error("用户UID获取失败！");
    acloading.value = false;
  }
  getActivityForUid(uid).then((res) => {
    if (res.data.code === 200) {
      activity.value = res.data.data;
      if (activity.value[0].count <= 0) {
        getActivityDialog.value = true;
      }
      acloading.value = false;
    } else {
      Message.error("获取优惠卷失败！");
      acloading.value = false;
    }
  });
}

// 获取最新汇率
// function getUSDTrates() {
//   loading.value = true;
//   getUSDTrate().then((res) => {
//     if (res.data.code === 200) {
//       rate.value = res.data.data;
//       money.value = amount.value;
//       loading.value = false;
//     } else {
//       Message.error(res.data.msg);
//       loading.value = false;
//     }
//   });
// }

function chooseMenu(value) {
  showMenu.value = value;
}
function getUserAmount() {
  loading.value = true;
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  getAmount(token, uid)
    .then((res) => {
      if (res.data.code === 200) {
        amount.value = res.data.data;
        money.value = res.data.data;
        // getUSDTrates();
        loading.value = false;
      } else {
        Message.error("用户余额获取失败！" + res.data.msg);
        loading.value = false;
      }
    })
    .catch((err) => {
      Message.error("用户余额获取失败！" + err.msg);
      loading.value = false;
    });
}

function beforeAvatarUpload(file) {
  upload.value = true;
  // this.loading = true;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const base64Image = reader.result; // 获取Base64格式的图片
    // 调用接口上传图片
    uploadImg(base64Image, {
      onUploadProgress: (progressEvent) => {
        progress.value = Math.floor(progressEvent.progress * 100);
        loading.text = "正在上传中 " + this.progress + "%";
      },
    })
      .then((response) => {
        if (response.data.code === 200) {
          imageUrl.value = response.data.data;
          // this.loading = false;
          //   loading.close();
          upload.value = false;
        } else {
          Message.error(response.data.msg);
          // this.loading = false;
          //   loading.close();
          upload.value = false;
        }
      })
      .catch((error) => {
        console.error("图片上传失败:", error);
        // this.loading = false;
        loading.close();
      });
  };
  return false; // 阻止默认的上传行为，因为我们手动上传图片
}

function setNewAvatars() {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  setNewAvatar(token, uid, imageUrl.value)
    .then((res) => {
      if (res.data.code === 200) {
        Message.success("头像设置成功！");
        localStorage.setItem("avatar", imageUrl.value);
        location.reload();
      } else {
        Message.error("头像设置失败！" + res.data.msg);
      }
    })
    .catch((err) => {
      //   Message.error("头像设置失败！" + err.data.msg);
      Message.error("头像设置失败！" + err.data.msg);
    });
}

function getMailCaptchas(value, type) {
  let mail = "";
  if (type === 1) {
    mail = localStorage.getItem("username");
  } else if (type === 2) {
    if (newMail.value == "") {
      Message.error("请输入新邮箱！");
      return;
    }
    mail = newMail.value;
  }
  if (mail) {
    let loadingInstance = Loading.service();
    let event;
    if (value == 1) {
      event = "changeemail";
    } else {
      event = "resetpwd";
    }
    getMailCaptcha(mail, event)
      .then((res) => {
        if (res.data.code === 200) {
          Message.success("发送成功！请查看您的邮箱");
          // 启动倒计时，60秒倒计时
          if (type === 2) {
            newCountdown.value = 60;
          } else {
            countdown.value = 60;
          }
          this.timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              clearInterval(this.timer); // 倒计时结束时清除定时器
            }
          }, 1000);
          nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
        } else {
          Message.error(res.data.msg);
          nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
        }
      })
      .catch((err) => {
        Message.error("发送失败" + err.msg);
        nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
      });
  } else {
    Message.error("请输入邮箱");
  }
}

function resetpwds() {
  let loadingInstance = Loading.service();
  resetpwd(this.username, this.reset.pwd, this.reset.captcha)
    .then((res) => {
      if (res.data.code === 1) {
        Message({
          message: "重置成功，即将推出登录",
          type: "success",
        });
        this.changePwdDialog = false;
        this.logOut();
        nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
      } else {
        Message({
          message: res.data.msg,
          type: "error",
        });
        nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
      }
    })
    .catch((err) => {
      Message({
        message: err.data.msg,
        type: "error",
      });
      this.changePwdDialog = false;
      nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
      });
    });
}

function logOut() {
  localStorage.removeItem("username");
  localStorage.removeItem("nickname");
  localStorage.removeItem("mobile");
  localStorage.removeItem("avatar");
  localStorage.removeItem("token");
  localStorage.removeItem("uid");
  localStorage.setItem("isLogin", "false");
  Message.success("退出登录成功！即将刷新页面");
  setTimeout(() => {
    router.replace("/login");
    location.reload();
  }, 1000);
}

function getMobile() {
  const mob = localStorage.getItem("mobile");
  if (mob == null || mob == "") {
    mobile.value = "未绑定";
    console.log("active");
  } else {
    mobile.value = mob;
  }
}

function checkUserIsLogin() {
  const token = localStorage.getItem("token");
  if (token == null || token == "") {
    router.push("/login");
  } else {
    getMobile();
    getUserAmount();
    getActivityForUids();
    getServiceLists();
    checkServerForUIDs();
  }
}

checkUserIsLogin();
</script>

<style>
.userMain {
  margin: 10px 150px;
}

.activeMenu {
  color: #cb1414;
  border-bottom: 0.5px #cb1414 solid !important;
}

.menu {
  border-bottom: 0.5px #909399 solid;
  margin: 20px 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.phone-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  z-index: 99;
  margin-bottom: 100px;
  margin-left: 10px;
  display: none;
}

.redBag {
  background-image: url("@/assets/activity/new-coupon-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  color: #ff6666;
  padding: 10px;
}

.redBag_dark {
  background-image: url("@/assets/activity/redpack_dark.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  color: black;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .userMain {
    margin: 10px 20px;
  }

  .phone-menu {
    display: block;
  }
}
</style>
